footer {
    background: rgba(0,0,0,0.4);
    box-shadow: 0 0 40px rgba(0,0,0,0.3), 0 40px 120px rgba(0,0,0,0.2) inset;
    margin-left: var(--layout-margin);
    margin-right: var(--layout-margin);
    margin-top: calc(var(--layout-margin) / 3 * 2);
    margin-bottom: calc(var(--layout-margin) / 3 * 2);
    z-index: 10;
    display: flex;
}


footer a {
    color: #87c5e2;
    padding: 0.8em 1.6em;
    line-height: 1.25em;
    text-align: center;
    flex-shrink: 1;
    display: flex;
    align-items: center;
}

@media(max-width: 420px) {
    footer a {
        padding: 1.2em 1.6em;
    }
}

footer a:is(:hover, :focus), footer a.active {
    text-decoration: none;
    color: #a3cff2;
    background: rgba(0,0,0,0.05);
    
}

footer div.divider {
    width: 1px;
    overflow: hidden;
    margin: 8px 0;
    background: #444;
}

footer div.divider:before {
    content: "|";
}
