#red {
    margin-top: 5vh;
    padding: 1em;
}

#red h1 {
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    margin: 1.5em 0 0.5em;
    font-size: 2rem;
}


#red input[type="text"], #red input[type="password"] {
    border: none;
    margin: 0;
    width: 100%;
    padding: 1em 2em;
}

#red input.bottom-line {
    border-bottom: 1px dotted #aaa;
}

#red button {
    width: 100%;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 0.9em;
    padding: 1em 2em;
}

#red a {
    color: white;
    opacity: 0.4;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 400;
}

#red a:hover {
    opacity: 1;
    text-decoration: underline;
}
