#report {
    padding-bottom: 0;
}


#report #pending-rescuers {
    margin-top: 1em;
}

#report div.inden1t {
    margin-left: 1em;
}

#report:after {
    content: "";
    display: table;
    clear: both;
}

#report #spinner {
    margin: 2em auto;
}

#report #head {
    margin-bottom: 0;
    background-color: var(--gss-mid-blue);
    display: flex;
}

#report textarea {
    width: 100%;
    min-height: 8em;
    padding: 0.3em 0.6em;
}

#report #report-area-date {
    margin-left: auto;
    text-align: right;
}

#report #report-area-date > * {
    text-transform: uppercase;
    padding: 0.5em;
    background: transparent;
    border: none;
    font-size: 1.05rem;
}

#report #report-area {
    margin-right: 1em;
    color: white;
    text-align: right;
    font-weight: bold;
}

#report #report-area option {
    color: black;
    background: white;
    font-weight: normal;
}

#report #report-date-box {
    display: inline-block;
    position: relative;
    color: white;
    font-weight: bold;
}

#report #report-date {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
}

#report #report-date.mobile {
    visibility: visible;
    opacity: 0;
}

#report section.white {
    margin: 0 0 1em 0;
}


#report #head h3 {
    color: white;
    margin: 0.2em 0;
}

#report div.warning {
    color: red;
}

#report div.daily-buttons {
    margin-top: 0.5em;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
}

#report div.daily-buttons button {
    width: 240px;
    display: flex;
    gap: 0.7em;
    align-items: baseline;
    margin: 0;
}

#report div.daily-buttons button span {
    flex-grow: 1;
    text-align: center;
}

#report #num-rescuers {
    display: flex;
    gap: 0.5em;
    margin-bottom: 0.5em;
}

#report #num-rescuers input {
    max-width: 4em;
}