#my-rescues {
    padding-bottom: 1em;
}

#my-rescues #head {
    display: flex;
    margin: 0;
    gap: 0.5em;
    align-items: baseline;
}

#my-rescues #head a {
    margin-left: auto;
    margin-top: 0.5em;
}

#my-rescues #head a:hover {
    text-decoration: none;
}

#my-rescues p {
    max-width: 70ch;
}