button.rescuer {
    border: none;
    background-color: transparent;
}

button.rescuer div.rescuer {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

button.rescuer div.rescuer:hover {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}



div.rescuer {
    text-transform: uppercase;
    display: inline-block;
    background: white;
    border-radius: 20px;
    padding: 7px;
    margin: 2px 4px;
    position: relative;
    font-weight: normal
}

div.rescuer div.rescuer-initials {
    background-size: cover;
    background-position: 50% 50%;
    vertical-align: middle;
    height: 30px;
    width: 30px;
    margin: 0;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    border-radius: 50%;
    background-color: #d9534f;
    color: white;
    display: inline-block
}

div.rescuer div.rescuer-data {
    font-size: 11px;
    color: black;
    vertical-align: middle;
    margin: 0 12px 0 8px;
    height: 28px;
    line-height: 14px;
    display: inline-block;
    text-align: left;
}

div.rescuer div.rescuer-name {
    font-size: 12px;
    padding-top: 1px;
    font-weight: normal
}

div.rescuer div.rescuer-info {
    opacity: 0.3;
    padding-top: 1px 0
}
