#profile {
}

#spinner.center {
    margin: 3em auto;
}

#profile #profile-main {
    display: flex;
    flex-wrap: wrap;
    background: white;
    box-shadow: var(--gss-box-shadow); 
}

#profile #profile-main > div {
    flex: 1;
}

#profile #profile-main #profile-picture {
    flex: 1;
    min-width: 260px;
}

@media(max-width: 640px) {
    #profile #profile-main #profile-picture {
        min-width: 220px;
    }
}

#profile #profile-main #profile-picture img {
    width: 100%;
}


#profile #profile-main #profile-tags {
    padding: 1em;
    flex-shrink: 1;
}

#profile #profile-main #profile-tags ul.tags {
    display: block;
}

#profile #profile-main #profile-tags ul.tags li {
    float: left;
    margin: 0.5em 1em;
    white-space: nowrap;
}


#profile #profile-main #profile-info {
    padding: 1em 2em;
    font-variant: small-caps;
    min-width: 30ch;
    flex-grow: 10;
    position: relative;
}

#profile #first-row {
    display: flex;
    flex-wrap: wrap;
    height: 1em;
}

#profile #first-row #profile-buttons {
    margin-left: auto;
    margin-right: -1em;
}

#profile #profile-main #profile-info #profile-name {
    font-size: 2em;
    font-weight: 700;
}


#profile #profile-main #profile-info #phone-buttons a {
    margin-left: 0.8em;
    font-size: 1.1em;
}


