#main-layout {
    --layout-margin: clamp(1rem, 5vw, 5em);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
}

main {
    z-index: 20;
    margin-left: var(--layout-margin);
    margin-right: var(--layout-margin);
    margin-top: calc(var(--layout-margin) / 2);
    margin-bottom: calc(var(--layout-margin) / 2);
    flex-grow: 1;
    flex-shrink: 0;
}

.shadow {
    box-shadow: var(--content-shadow);
    background: white;
}

.container-fluid.padding {
    padding: 0 1em;
}

@media(max-width: 800px) {

    #main-layout {
        --layout-margin: 0;
    }

}


