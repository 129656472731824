#profile-notes {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin: 1em 0;
}

#profile-notes:empty {
    display: none;
}

#profile-notes div.note {
    flex: 1;
    min-width: 45ch;
    max-width: 50%;
    border-radius: 0;
    border: none;
    background: white;
    box-shadow: var(--gss-box-shadow);
    margin: 0;
}

@media(max-width: 800px) {

    #profile-notes div.note {
        min-width: 100%;
        max-width: auto;
    }

}

#new-note-area {
    margin: 1em;
}




