#search-header {
    display: flex;
    gap: 1em;
    margin-bottom: 0.5em;
}

#search-header a {
}

#search-header a.active {
    text-decoration: underline;
}
