#login #message {
    height: 1.5em;
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: bold;
    color: white;
    font-variant: small-caps;
}

#login #below {
    margin-top: 2em;
}

#login h4 {
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
}

#login p, #login ul {
    color: white;
}

#login i.fa-solid {
    margin-right: 0.8ch;
}

#login #below.flash a {
    animation: Flash 7s ease infinite;
}

#login #below.flash a:hover {
    animation: none;
}

@keyframes Flash { 
    0% { opacity: 0.4; text-shadow: none }
    30% { opacity: 0.4; text-shadow: none }
    40% { opacity: 0.8; text-shadow: 0 0 2px rgba(255, 255, 255, 0.4) }
    80% { opacity: 0.4; text-shadow: none }
    100% { opacity: 0.4; text-shadow: none }
}
