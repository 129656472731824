#submenu {
    list-style: none;
    padding-left: 0;
    margin-top: 2em;
}

#spinner {
    background-image: url("../images/spinner.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

#spinner, #no-spinner {
    width: 90px;
    height: 12px;
}

#spinner.large, #no-spinner.large {
    width: 120px;
    height: 16px;
}

#spinner.small, #no-spinner.small {
    width: 90px;
    height: 12px;
}

#spinner.center {
    margin: 2em auto;
    display: block;
}

#not-found {
    margin-top: 35vh;
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.2;
}
