#rescuers {
    margin-top: 3em
}

#rescuers div.search-box {
    margin-bottom: 2.5em;
}

#rescuers #rescuer-count {
    padding: 0 0.5em 0.5em;
    font-style: italic;
    opacity: 0.7;
}

#rescuers #tag-filter {
    display: flex;
    align-items: flex-start;
    align-items: baseline;
    gap: 0.5em;
}

#rescuers #tag-filter input {
    padding: 0.2em 0.5em;
    height: auto;
    border: 1px solid #ccc;
}

#rescuers #tag-filter i, #rescuers #tag-filter span {
    opacity: 0.5;
    font-style: italic;
}

#rescuers #tags {
    margin: 1em;  
}

#rescuers ul.tags {
    margin: 0 auto 1em;
    padding: 1em 1em;
}

#rescuers ul.tags li {
    cursor: pointer;
}
