#tag-editor #tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75em;
    margin: 3em 0;
    font-size: 0.9em;
}


#tag-editor div.big-tag {
    border: none;
    border-radius: 0 0.3em 0.3em 0;
    padding: 0.3em 1em 0.3em 1em;
    position: relative;
    background-color: var(--tag-color);
    color: white;
    font-size: 1em;
    height: 3.2em;
    margin-left: 2em;
    border-left: 1px solid var(--tag-color);
}



#tag-editor div.big-tag:before {
	content: "";
	float: left;
	position: absolute;
	top: 0;
	left: -1.6em;
	width: 0;
	height: 0;
	border-color: transparent var(--tag-color) transparent transparent;
	border-style: solid;
	border-width: 1.6em 1.6em 1.6em 0;
}


#tag-editor div.big-tag button {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 0.1em;
    right: 0.5em;
    color: white;
}

#tag-editor div.big-tag div.tag-name {
    font-weight: bold;
    padding-right: 1.5em;
    text-transform: uppercase;
    font-size: 0.9em;
}

#tag-editor div.big-tag div.tag-description {
    opacity: 0.7;
    font-size: 0.9em;
}

