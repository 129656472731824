#note-editor {
    background: white;
    padding: 1em;
    min-width: 45ch;
    max-width: 80ch;
}

#note-editor label {
    margin-bottom: 0.25em;
}

#note-editor input[type="text"] {
    padding: 0.2em 0.4em;
    width: 100%;
}

#note-editor textarea {
    min-height: 11em;
    padding: 0.2em 0.4em;
    width: 100%;
}

#note-editor .note-editor-buttons {
    margin-top: 1em;
}

#note-editor select {
    background: white;
    border: 1px solid grey;
    padding: 0.2em 0.4em;
}

#note-editor .hint {
    opacity: 0.6;
    font-size: 0.9em;
}

#note-editor #public-note-box {
    font-size: 1.3em;
}

#note-editor #public-note-box label {
    font-size: 1rem;
}