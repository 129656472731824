#rescuer-select input {
    margin: 0.5em 0;
    padding: 0.2em 0.5em;
    border: none;
    border-bottom: 1px solid #aaa;
    min-width: 20em;
}

#rescuer-select div.search-results {
    margin: 0.5em 0;
}
