div.note {
    position: relative;
    padding: 1em;
    margin: 0 0 1em 0
}

div.note div.title {
    font-weight: bold
}

div.note div.subtitle {
    color: rgb(153, 153, 153);
    margin-bottom: 0.5em;
    font-size: 0.9em
}

div.note div.text {
    font-style: italic
}

div.note div.note-buttons {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
}

div.note div.tags {
    margin-top: 1.5em;
}

div.note div.text {
    white-space: pre-wrap;
}
