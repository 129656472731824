#shift div.info {
    text-align: right;
    font-size: 0.9em;
    opacity: 0.6;
}

#shift div.shift-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5em 0;
}

#shift div.shift-row > div {
    flex-grow: 1;
    flex-shrink: 0;
}

#shift div.shift-row > div.buttons {
    text-align: right;
}