#weeks {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1em;
    padding: 1em;
}

#weeks div.week {
    min-width: 220px;
    min-height: 260px;
    padding: 0.7em 1em 1em;
    background: white;
    font-size: 0.9rem;
    position: relative;
    box-shadow: 1px 2px 10px rgba(0,0,0,0.2);
}

#weeks div.week.clickable {
    cursor: pointer;
}

#weeks div.week button.details {
    margin-left: 1em;
    font-size: 0.9em;
    font-style: italic;
}

#weeks div.week button.details:not(.active):focus {
    text-decoration: none;
}

#weeks div.week button.details.active {
    text-decoration: underline;
}

#weeks div.week div.alert {
    padding: 0.25em 0.5em;
}

#weeks div.week.disabled {
    background: transparent;
    opacity: 0.4;
    pointer-events: none;
}

#weeks div.week h4 {
    font-size: 1em;
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
    padding: 0 0.2em 0.2em;
    display: inline-block;
}

#weeks div.week hr {
    margin: 0.5em 0;
}

#weeks div.week :is(ol, ul) {
    padding-left: 1em;
    margin: 0.5em 0;
    z-index: 2;
    opacity: 0.75;
    font-size: 0.9em;
}

#weeks div.week ol {
    padding-left: 1.4em;
}


#weeks div.week div.wish {
    position: absolute;
    top: 0em;
    right: 0em;
    background: #8cd4bd;
    font-size: 1.1em;
    font-weight: bold;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    z-index: 1;
}

#weeks div.week div.wish-1 {
    background-color: var(--wish-1-green);
}

#weeks div.week div.wish-2 {
    background-color: var(--wish-2-green);
}

#weeks div.week div.wish-3 {
    background-color: var(--wish-3-green);
}

#weeks div.week div.wish-4 {
    background-color: var(--wish-4-green);
}

#weeks div.week li.canceled {
    text-decoration: line-through;
    color: var(--gss-strong-red);
}