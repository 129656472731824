#rescues {
    margin: 3em 0;
}

#rescues #spinner, #rescues #no-spinner {
    margin: 1.5em auto;
}

#rescues #rescue-count {
    padding: 0.5em 0.5em 0;
    font-style: italic;
    opacity: 0.7;
}

#rescues #example {
    margin-left: 1em;
}
