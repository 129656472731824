#wishes div {
    text-transform: uppercase;
    font-size: 0.8rem;
}

#wishes input[type="radio"] {
    display: none;
}

#wishes label {
    padding: 0.7em 1.2em;
    font-weight: bold;
    cursor: pointer;
    color: rgba(0,0,0,0.7)
}

#wishes input[type="radio"]:checked+label {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6) inset;
  color: black;
}

#wishes label[for="wish-1"] {
    background-color: var(--wish-1-green);
}

#wishes label[for="wish-2"] {
    background-color: var(--wish-2-green);
}

#wishes label[for="wish-3"] {
    background-color: var(--wish-3-green);
}

#wishes label[for="wish-4"] {
    background-color: var(--wish-4-green);
}

#wishes label[for="wish-0"] {
    background-color: rgba(217,83,79,0.6);
}
