#pending-uploads {
    display: flex;
    flex-direction: column;
}

#pending-uploads .preview {
    width: 120px;
}

#pending-uploads i.preview {
    font-size: 6em;
    display: inline-block;
    text-align: center;
}

#pending-uploads iframe.preview {
    height:200px;
}

#pending-uploads div.pending-upload {
    display: flex;
    gap: 1em;
    border: 1px solid #aaa;
    border-radius: 0.5em;
    padding: 1em;
    margin-bottom: 2em;
}

#pending-uploads div.pending-upload div.vertical {
    display: flex;
    flex-direction: column;
}

#pending-uploads div.pending-upload div.buttons {
    display: flex;
    gap: 0.5em;
    margin-top: 0.5em;
}
