#timeline-header {
    position: relative;
    height: 4em;
}

#timeline-header div.day {
    position: absolute;
    border-left: 1px dotted gray;
    top: 0;
    bottom: 0;
    min-width: 1px;
    padding-left: 0.3em;
    font-size: 0.8rem;
    color: black;
    z-index: 20;
}

#timeline-header div.day[data-weekday="5"] {
    border-left: 2px dotted black;
}

