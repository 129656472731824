nav {
    color: rgb(209, 209, 209);
    background: rgba(0,0,0,0.4);
    box-shadow: 0 0 40px rgba(0,0,0,0.3), 0 40px 120px rgba(0,0,0,0.2) inset;
    margin-left: var(--layout-margin);
    margin-right: var(--layout-margin);
    margin-top: calc(var(--layout-margin) / 3 * 2);
    z-index: 10;
    flex-shrink: 0;
}


nav #top {
    display: flex;
    --link-color: #eee;
}

nav #top .top {
    padding: 0.8em 1.2em;
    line-height: 1.25em;
}

nav #top a {
    color: var(--link-color);
    text-transform: uppercase;
    font-weight: 600;
}

nav #top a:focus {
    color: var(--link-color);
    font-weight: 600;
}

nav #top a:hover {
    text-decoration: none;
    color: white;
}

nav #top-1 {
    background: rgba(217, 83, 79, 0.95);
    display: flex;
    align-items: center;
}

nav #top-1:hover {
    background: rgba(217, 83, 79, 1);
}

nav #top-1 img {
    height: 4em;
    margin: -2em 0.75em -2em -0.5em;    
}

@media(max-width: 420px) {

    nav #top-1 {
        max-width: 28ch;
    }
    
    nav #top-1 img {
        height: 3em;
        margin: -1.5em 0.75em -1.5em -0.5em;    
    }
}

nav #top #top-2 {
    margin-left: auto;
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-right: 0.2em;
    font-size: 1.1rem;
}

nav #top #top-2 a {
    margin-left: auto;
}

nav #top #top-3 {
    display: flex;
    align-items: center;
}


nav #menu {
    --old-light-blue: #489fe5;
    --old-lightest-blue: #a3cff2;
    
    --menu-link-color: #87c5e2;
    --menu-active-color: var(--old-lightest-blue);
    --menu-group-title-color: #000;
    --menu-group-divider-color: #555;
}

nav #menu {
    padding: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s;
}

nav #menu #info {
    margin: 1.5em 2em 0 2em;
    color: var(--menu-group-title-color);
    font-variant: small-caps;
    font-size: 0.9rem;
    font-weight: 100;
    display: flex;
    gap: 0.6em;
}

nav #menu #info button.a, nav #menu #info a {
    padding: 0;
    color: #444;
    border: none;
}

nav #menu #info button.a:hover, nav #menu #info a:hover {
    color: var(--menu-group-title-color);    
}

nav #menu-inner {
    display: flex;
    gap: 1em;
    padding: 1em 2em;
    margin-top: 0.5em;
    flex-wrap: wrap;
}

nav #menu.open {
    max-height: 100vh;
}

nav #menu div.menu {
    padding-right: 1em;
    border-right: 1px solid var(--menu-group-divider-color);
}

@media(max-width: 800px) {
    nav #menu div.menu {
        border-right: none;
    }

}

nav #menu div.menu:last-child {
    border-right: none;
}


nav #menu div.menu h4 {
    font-size: 0.9rem;
    color: var(--menu-group-title-color);
    font-weight: 100;
    font-variant: small-caps;
}

nav #menu div.menu ul {
    color: #888;
    padding-left: 0.5em;
    font-size: 1rem;
    list-style: none;
}


nav #menu a, nav #menu button.a {
    color: var(--menu-link-color);
    border-left: 4px solid transparent;
    padding-left: 6px;
    display: block;
}

nav #menu a.active {
    color: var(--menu-active-color);
    border-left-color: var(--menu-active-color);
}


nav #menu a:is(:hover, :focus), nav #menu button.a:hover {
    color: var(--menu-active-color);
    text-decoration: none;
}

#nav-icon3 {
    --icon-color: #eee;
    background: transparent;
    border: none;
    width: 20px;
    height: 16px;
    position: relative;
    margin: 0 0 0 auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

#nav-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: var(--icon-color);
    border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
    top: 0px;
}

#nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
    top: 6px;
}

#nav-icon3 span:nth-child(4) {
    top: 12px;
}

#nav-icon3.open span:nth-child(1) {
    top: 6px;
    width: 0%;
    left: 50%;
}

#nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
    top: 6px;
    width: 0%;
    left: 50%;
}