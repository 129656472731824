div.tag {
	height: 24px;
	line-height: 22px;
	position: relative;
	font-size: 0.85em;
	color: white;
	background-color: var(--tag-color);
	padding: 0 10px 0 14px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	box-shadow: 1px 1px 2px #004977;
	display: inline-block;
	margin: 0.5em 1em;
}

div.tag:before {
	content: "";
	float: left;
	position: absolute;
	top: 0;
	left: -12px;
	width: 0;
	height: 0;
	border-color: transparent var(--tag-color) transparent transparent;
	border-style: solid;
	border-width: 12px 12px 12px 0;
}


div.tag:after {
	content: "";
	position: absolute;
	top: 10px;
	left: 0;
	float: left;
	width: 4px;
	height: 4px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background: #fff;
	-moz-box-shadow: -1px -1px 2px #004977;
	-webkit-box-shadow: -1px -1px 2px #004977;
	box-shadow: -1px -1px 2px #004977;
}

div.tag.clickable {
    cursor: pointer;
}
