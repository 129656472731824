#shifts {
}

#clear {
    height: 1px;
}

#shifts #select-area select {
    background: transparent;
    border: none;
    padding: 0.5em 0.2em;
    text-align: right;
    font-weight: bold;
    text-transform: uppercase;
}

#shifts #select-area option {
    background: white;
    color: black;
    font-weight: normal;
}

#shifts #select-area {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--gss-mid-blue);
}

#shifts #select-area h3 {
    flex-grow: 1;
    flex-shrink: 0;
    color: white;
}

#shifts #select-area select {
    color: white;
}

#shifts #select-area option {
    color: black;
}

#shifts section.white {
    box-shadow: var(--gss-box-shadow);   
    margin: 0; 
}