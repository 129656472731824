#spinner {
    --spinner-width: 90px;
    width: var(--spinner-width);
    height: calc(var(--spinner-width) / 7.5);
}

#spinner.active {
    background-image: url("./Spinner.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


#spinner.large {
    --spinner-width: 120px;
}

#spinner.x-large {
    --spinner-width: 160px;
}

#spinner.center {
    margin: 1em auto;
    display: block;
}