#tag-assign {
}

#tag-assign select {
    background: var(--tag-color);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.3em 0.6em;
}


#tag-assign #spinner {
    display: inline-block;
    margin-left: 0.6em;
}

#tag-assign h4 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
}

#tag-assign input[type="text"] {
    min-width: min(50ch, 100%);
    margin-bottom: 1em;
    padding: 0.3em 0.6em;
}