#new-rescuer {
    margin-bottom: 2em;
    max-width: 80ch
}

#new-rescuer section.white {
    padding-bottom: 1.5em;
}

#new-rescuer #spinner {
    display: inline-block;
    width: 90px;
    height: 12px;
    margin-left: 0.5em;
    margin-bottom: 0
}

#new-rescuer input[type="text"] {
    display: block;
}