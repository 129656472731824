div.intervals {
    position: relative;
    width: 100%;
    height: 2em;
    height: 100%;
}

div.intervals div.interval {
    position: absolute;
    top: 20%;
    bottom: 20%;
    background-color: rgba(0,0,0,0.3);
    z-index: 1;
    color: white;
    padding-left: 0.5em;
    line-height: 2em;
    overflow: hidden;
    z-index: 30;
    font-size: 0.8rem;
}

div.intervals div.day {
    position: absolute;
    border-left: 1px dotted gray;
    top: 0;
    bottom: 0;
    width: 1px;
    z-index: 20;
}

div.intervals div.day[data-weekday="5"] {
    border-left: 2px dotted black;
}

div.intervals div.day[data-weekday="6"] {
    border-left: 2px dotted black;
}

div.intervals div.day[data-weekday="0"] {
    border-left: 2px dotted black;
}