#timeline #graph {
    display: grid;
    grid-template-columns: max-content auto;
    padding: 2em 1em;
}

#timeline #spinner {
    margin: 2em auto;
}

#timeline ul {
    margin: 2em 0;
}

#timeline #timeline-form {
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;
    gap: 0.6em;
}

#timeline #timeline-form span {
    margin-right: 0.6em;
}

#timeline div.date-box {
    position: relative;
}

#timeline div.date-box input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

#timeline #timeline-form .selector {
    border: 1px solid black;
    background: white;
    padding: 0.3em 0.6em;
    border-radius: 4px;
}
