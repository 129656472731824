#report-info-fields {
    display: inline-grid;
    gap: 0.7em 1em;
    grid-template-columns: auto auto;
}

#report-info-fields > div {
    line-height: 1.1em;
}

#report-info-fields > div.right {
    align-self: center;
}

#report-info-fields > div.flex {
    display: flex;
    gap: 1.5em;
}


#report-info .warning {
    --warning-color: red;
    background: var(--warning-color);
    color: white;
    padding: 0.1em 0.5em;
    position: relative;
	line-height: 22px;
	display: flex;
    flex-shrink: 1;
}

#report-info .warning:before {
	content: "";
	float: left;
	position: absolute;
	top: 0;
	left: -12px;
	width: 0;
	height: 0;
	border-color: transparent var(--warning-color) transparent transparent;
	border-style: solid;
	border-width: 12px 12px 12px 0;
}

#report-info-fields div.rescuer {
    margin-left: -0.5em;
}