#handbook {
    padding-bottom: 1em;
}

#handbook section.top {
    margin-top: 0;
}

#handbook section.top h3 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.2em;
    margin-bottom: 1em;
}

#handbook a.btn:hover {
    text-decoration: none;
}

#handbook a i.fas {
    margin-right: 0.5em;
}

#handbook h4 {
    font-size: 1.1rem;
}

#handbook #big-ones {
  display: grid;
  gap: 0.5em;
}

@media(min-width: 800px) {

    #handbook #big-ones {
        display: flex;
    }
}

#handbook #big-ones a {
}

#handbook section.white {
}