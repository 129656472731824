
#applications {
    background: #f7f7f7;
}

#applications #header {
    display: flex;
    background: #abd2b7;
    color: white;
    margin: 0;
}

#applications #header h3 {
    color: white;
}

#applications #header select {
    margin-left: auto;
    background: transparent;
    color: white;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    text-align: right;
}

#applications #header option {
    background: white;
    color: black;
    font-weight: normal;
}

#applications #info {
    margin: 0;
    padding: 1em;
}

#applications #info span {
    margin-right: 0.6em;
}

#applications #info p {
    text-transform: uppercase;
    font-size: 0.8rem;
}

#applications #info span.alert-inline {
    display: inline;
    padding: 0 0.2em;
    margin: 0;
}
