div.rescue {
    position: relative;
    overflow: hidden;
    background: white;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
    border-radius: 4px;
    background: white;
    padding: 1em 45px 1em 1em;
    margin: 0.5em 0;
}

div.rescue:hover {
    box-shadow: 2px 2px 6px rgba(0,0,0,0.35);
}

div.rescue span.short-id {
    display: none;
    position: absolute;
    bottom: -8px;
    right: 0px;
    opacity: 0.05;
    font-size: 2em;
    font-weight: 700;
}

div.rescue span.short-rescuer {
    position: absolute;
    transform: rotate(90deg);
    right: -30px;
    top: calc(50% - 8px);
    width: 100px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    z-index: 100;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px
}

div.rescue div.rescue-tag {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    opacity: 0.5
}

div.rescue div.rescue-tag.open {
    display: block;
    background-color: #d40000
}

div.rescue div.rescue-tag.submitted {
    display: none;
    background-color: #84cb4a
}

div.rescue.submitted div.rescue-tag.open {
    display: none
}

div.rescue.submitted div.rescue-tag.submitted {
    display: block
}

div.rescue div.open-warning {
    display: block;
    position: absolute;
    bottom: 4px;
    right: 44px;
    font-size: 9px;
    text-transform: uppercase;
    text-align: right;
    line-height: 10px;
    color: #e97f7f;
    font-weight: bold
}

div.rescue.submitted div.open-warning {
    display: none
}

a.rescue {
    color: black;
    outline: 0
}

a.rescue:hover {
    text-decoration: none;
    color: black
}

div.rescue div.name {
    font-size: 1.2em;
    font-weight: bold
}

div.rescue div.info {
    color: #777;
    font-size: 0.85em
}

div.rescue span.missing {
    opacity: 0.3;
    font-weight: normal
}
