#profile-picture-box {
    text-align: right;
    position: relative;
}

#profile-picture-box label {
    position: absolute;
    bottom: 0.3em;
    right: 0.3em;
    padding: 0.1em 0.4em;
    margin: 0;
}


#profile-picture-box #profile-picture {
    width: 100%;
    display: block;
    min-height: 256px;
    border: 1px solid gray;
    border-radius: 2px;
}

#profile-picture-box #profile-picture.empty {
    min-height: 256px;
}

#profile-picture-box #pic-file {
    display: none;
}