div.subsection {
    background-color: white;
    margin-bottom: 1em;
    padding: 1em;
}

#rescuer-editor {
}

#rescuer-editor #buttons {
    display: flex;
}


#rescuer-editor #buttons #rescuer-delete {
    margin-left: auto;
    transform: scale(0.5);
    transform-origin: top right;
}

#rescuer-editor div.note {
    max-width: 100%;
}


input {
    display: block;
    margin-bottom: 1em;
}

div.fields input[type='text'], div.fields input[type='date'] {
    width: 100%;
    padding: 0.75em 0.75em 0.5em;
    border: 1px solid #888;
    border-radius: 4px;
}

div.fields label {
    color: #666;
    font-size: 0.9em;
    position: relative;
    padding: 0 0.3em; top: 0.66em;
    left: 0.5em;
    background: white;
}
